import googleApi from '@store/api/googleApi';
const googleTranslationApiKey = process.env.REACT_APP_GOOGLE_TRANSLATE_API_KEY;
const googleTranslationApi = googleApi.injectEndpoints({
    endpoints: (builder) => ({
        detectTextLanguage: builder.query({
            query: ({ text }) => ({
                url: `/detect?q=${text}&key=${googleTranslationApiKey}`,
                method: 'POST',
            }),
            transformResponse: (response) => {
                var _a, _b;
                const detectedLanguage = (_b = (_a = response.data.detections) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b[0];
                // NOTE: GoogleAPI response with `.language === 'und'`
                // when the language is undefined
                if (detectedLanguage && detectedLanguage.language !== 'und') {
                    return detectedLanguage;
                }
                return undefined;
            },
        }),
        translateText: builder.query({
            query: ({ text, targetLanguageCode }) => ({
                url: `?q=${text}&target=${targetLanguageCode}&key=${googleTranslationApiKey}`,
                method: 'POST',
            }),
            // eslint-disable-next-line max-len
            transformResponse: (response) => response.data.translations[0],
        }),
    }),
});
export default googleTranslationApi;
export const { useDetectTextLanguageQuery, useLazyDetectTextLanguageQuery, useTranslateTextQuery, useLazyTranslateTextQuery, } = googleTranslationApi;
