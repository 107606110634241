const MuiTextFieldConfig = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            '& .MuiOutlinedInput-root': Object.assign(Object.assign({}, (ownerState.size === 'small' && {
                height: 40,
            })), { '& fieldset': Object.assign(Object.assign({}, (!ownerState.error && {
                    border: `1px solid ${theme.customColors.input.border}`,
                })), (ownerState.error && {
                    borderWidth: '1px',
                })), '&:hover fieldset': Object.assign(Object.assign({}, (!ownerState.error && {
                    border: `1px solid ${theme.customColors.input.borderHover}`,
                })), (ownerState.error && {
                    borderWidth: '1px',
                })), '&.Mui-focused fieldset': Object.assign(Object.assign({}, (!ownerState.error && {
                    border: `1px solid ${theme.customColors.input.borderActive}`,
                    boxShadow: `0px 0px 0px 3px ${theme.customColors.surface.shadow}`,
                })), (ownerState.error && {
                    borderWidth: '1px',
                    boxShadow: '0px 0px 0px 3px rgba(229, 72, 77, 0.20)',
                })) }),
        }),
    },
};
export default MuiTextFieldConfig;
