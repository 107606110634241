var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * request for restAPI
 */
const restBaseQueryFn = (_a) => __awaiter(void 0, [_a], void 0, function* ({ url, method, body, headers, params, }) {
    let response;
    let responseJSON;
    try {
        let requestUrl = url;
        if (typeof params !== 'undefined') {
            requestUrl += `?${new URLSearchParams(params).toString()}`;
        }
        response = yield fetch(requestUrl, {
            method,
            body: body instanceof FormData ? body : JSON.stringify(body),
            headers,
        });
        // we can`t take json from 204
        if ((response === null || response === void 0 ? void 0 : response.status) === 204) {
            responseJSON = { data: response };
        }
        else {
            responseJSON = yield response.json();
        }
    }
    catch (error) {
        if (error instanceof SyntaxError) {
            console.error('Unexpected SyntaxError while parsing response on base request', { error });
        }
        else {
            console.error('Could not reach backend service', error);
        }
    }
    if (response === null || response === void 0 ? void 0 : response.ok) {
        return { data: responseJSON };
    }
    // return error
    return {
        error: {
            status: response === null || response === void 0 ? void 0 : response.status,
            data: (response === null || response === void 0 ? void 0 : response.statusText) || responseJSON.message,
            original: response,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            code: responseJSON.code,
        },
    };
});
const restBaseQuery = ({ baseUrl = '', }) => (args, api) => __awaiter(void 0, void 0, void 0, function* () {
    return restBaseQueryFn({
        body: args.body,
        getState: api.getState,
        headers: args.headers,
        method: args.method,
        url: `${baseUrl}${args.url || ''}`,
        encodedUrlPart: args.encodedUrlPart,
    });
});
export { restBaseQueryFn, restBaseQuery, };
