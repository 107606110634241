import { createTheme } from '@mui/material';
import merge from 'deepmerge';
import { MuiButtonBaseConfig, MuiButtonConfig } from '@app/theme/components/MuiButton';
import MuiTextFieldConfig from '@app/theme/components/MuiTextField';
import MuiOutlinedInputConfig from '@app/theme/components/MuiOutlinedInput';
import MuiSelectConfig from '@app/theme/components/MuiSelect';
import MuiPopoverConfig from '@app/theme/components/MuiPopover';
import MuiPaginationItemConfig from '@app/theme/components/MuiPaginationItem';
import darkConfig from './themes/dark.json';
import lightConfig from './themes/light.json';
const baseConfig = {
    components: {
        MuiButtonBase: MuiButtonBaseConfig,
        MuiButton: MuiButtonConfig,
        MuiTextField: MuiTextFieldConfig,
        MuiOutlinedInput: MuiOutlinedInputConfig,
        MuiSelect: MuiSelectConfig,
        MuiPopover: MuiPopoverConfig,
        MuiPaginationItem: MuiPaginationItemConfig,
    },
    typography: {
        fontFamily: 'Inter, sans-serif',
        h1: {
            fontWeight: 600,
            fontSize: 32,
            lineHeight: '40px',
        },
        h2: {
            fontSize: 24,
            fontWeight: 600,
            lineHeight: '40px',
        },
        h3: {
            fontSize: 20,
            fontWeight: 600,
            lineHeight: '40px',
        },
        h4: {
            fontSize: 18,
            fontWeight: 600,
            lineHeight: '40px',
        },
        h5: {
            fontSize: 18,
            fontWeight: 600,
            lineHeight: '40px',
        },
        h6: {
            fontSize: 18,
            fontWeight: 600,
            lineHeight: '40px',
        },
        subtitle1: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '20px',
        },
        subtitle2: {
            fontSize: 12,
            fontWeight: 500,
            lineHeight: '20px',
        },
        body1: {
            fontSize: 14,
            lineHeight: '20px',
        },
        body2: {
            fontSize: 12,
            lineHeight: '20px',
        },
        caption: {
            fontSize: 10,
            lineHeight: '20px',
        },
    },
    shape: {
        borderRadius: 6,
    },
    spacing: 6,
    spacing_sizes: {
        s: 6,
        xs: 8,
        m: 12,
        xm: 18,
        l: 24,
        xl: 48,
    },
    baseSize: 6,
    timingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
};
const lightTheme = createTheme(merge(baseConfig, lightConfig));
const darkTheme = createTheme(merge(baseConfig, darkConfig));
const Themes = {
    light: lightTheme,
    dark: darkTheme,
};
export const getThemeConfig = (themeKey) => Themes[themeKey];
