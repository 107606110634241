var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import refreshTokenMiddleware from '@app/store/api/queries/refreshTokenMiddleware';
import { restBaseQueryFn } from './baseQuery';
/**
 * use this request when restAPI is needed
 */
const restBaseQueryAuth = ({ baseUrl = '', }) => (args, api) => __awaiter(void 0, void 0, void 0, function* () {
    const request = () => {
        const headersInstance = new Headers(args.headers);
        const { auth } = (api.getState()).auth;
        let searchParams;
        if (auth === null || auth === void 0 ? void 0 : auth.accessToken) {
            headersInstance.set('authorization', `Bearer ${auth.accessToken}`);
        }
        if (args.params) {
            if (typeof args.params === 'string') {
                searchParams = new URLSearchParams(args.params);
            }
            else if (args.params) {
                const argParams = args.params;
                searchParams = new URLSearchParams();
                Object.keys(argParams).forEach((key) => {
                    if (argParams[key]) {
                        searchParams.append(key, argParams[key]);
                    }
                });
            }
        }
        return restBaseQueryFn({
            body: args.body,
            getState: api.getState,
            headers: headersInstance,
            method: args.method,
            url: `${baseUrl}${args.url || ''}`,
            params: searchParams,
        });
    };
    return refreshTokenMiddleware(api, request);
});
export default restBaseQueryAuth;
