const avatarImageToFormData = (image) => {
    const formData = new FormData();
    if (image) {
        const { avatar, original, thumbnail, } = image;
        formData.append('avatar', avatar);
        formData.append('original', original);
        formData.append('thumbnail', thumbnail);
    }
    return formData;
};
export { 
// eslint-disable-next-line import/prefer-default-export
avatarImageToFormData, };
