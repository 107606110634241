const MuiPaginationItemConfig = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            '&:hover': {
                backgroundColor: theme.customColors.shades.primaryLight,
            },
            '&:focus': {
                boxShadow: '0px 0px 0px 3px rgba(62, 99, 221, 0.20)',
            },
            '&:active': {
                backgroundColor: theme.palette.primary.light,
                boxShadow: '0px 0px 0px 3px rgba(62, 99, 221, 0.20)',
            },
            '&:not(.Mui-selected)': {
                '&:hover,:focus,:active': {
                    color: theme.customColors.portfolio.hover,
                },
            },
            '&.Mui-selected': {
                backgroundColor: theme.customColors.portfolio.hover,
                '&:hover,:focus,:active': {
                    boxShadow: 'none',
                    backgroundColor: theme.customColors.portfolio.hover,
                },
            },
        }),
    },
};
export default MuiPaginationItemConfig;
