var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Mutex } from 'async-mutex';
import { logout } from '@entities/auth';
import { IsBaseRestQueryErrorResponse, } from '@app/store/api/queries/types';
import { authApiV1 } from '@shared/api/auth';
const mutex = new Mutex();
const refreshTokenMiddleware = (api, originalApiCallFn) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    // wait until the mutex is available without locking it
    yield mutex.waitForUnlock();
    let result = yield originalApiCallFn();
    if (IsBaseRestQueryErrorResponse(result) &&
        result.error.status === 401) {
        if (!mutex.isLocked()) {
            const releaseMutex = yield mutex.acquire();
            try {
                const refreshToken = ((_a = api.getState().auth.auth) === null || _a === void 0 ? void 0 : _a.refreshToken) || '';
                const refreshTokenRequest = api.dispatch(authApiV1
                    .endpoints
                    .refreshToken
                    .initiate({ refreshToken }, { track: false }));
                const refreshTokenResult = yield refreshTokenRequest;
                // resetting the cache
                refreshTokenRequest.reset();
                if (refreshTokenResult.data) {
                    // retry the initial query
                    result = yield originalApiCallFn();
                }
                else {
                    api.dispatch(logout());
                }
            }
            finally {
                // release must be called once the mutex should be released again.
                releaseMutex();
            }
        }
        else {
            // wait until the mutex is available without locking it
            yield mutex.waitForUnlock();
            result = yield originalApiCallFn();
        }
    }
    return result;
});
export default refreshTokenMiddleware;
