import { avatarImageToFormData } from '@store/slices/file/utils';
import restApiAuthorized from '@store/api/restApiAuthorized';
const fileApi = restApiAuthorized.injectEndpoints({
    endpoints: (builder) => ({
        sendAvatarImage: builder.mutation({
            query: ({ profileId, image }) => ({
                url: `/api/v1/profile/${profileId}/avatar`,
                method: 'POST',
                body: avatarImageToFormData(image),
            }),
            invalidatesTags: ['Me'],
        }),
        deleteAvatarImage: builder.mutation({
            query: ({ id }) => ({
                url: `/api/v1/profile/${id}/avatar`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Me'],
        }),
    }),
});
export default fileApi;
export const { useSendAvatarImageMutation, useDeleteAvatarImageMutation, } = fileApi;
