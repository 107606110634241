import { createSlice } from '@reduxjs/toolkit';
const SliceName = 'app';
const initialState = {
    sidebarComponent: null,
    isSidebarOpen: false,
    isNavSidebarOpen: false,
    language: 'en',
    theme: 'light',
    areDevComponentsEnabled: false,
};
const profileSlice = createSlice({
    name: SliceName,
    initialState,
    reducers: {
        openSidebar: (state, { payload }) => {
            state.isSidebarOpen = true;
            state.sidebarComponent = payload;
        },
        closeSidebar: (state) => {
            state.isSidebarOpen = false;
            state.sidebarComponent = null;
        },
        toggleNavSidebar: (state) => {
            state.isNavSidebarOpen = !state.isNavSidebarOpen;
        },
        changeTheme: (state, action) => {
            state.theme = action.payload;
        },
        changeLanguage: (state, action) => {
            state.language = action.payload;
        },
        reset: () => initialState,
        toggleDevComponents: (state) => {
            state.areDevComponentsEnabled = !state.areDevComponentsEnabled;
        },
    },
});
export { SliceName };
export const { closeSidebar, openSidebar, reset, toggleNavSidebar, changeTheme, changeLanguage, toggleDevComponents, } = profileSlice.actions;
export const applicationReducer = profileSlice.reducer;
