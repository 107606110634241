const MuiSelectConfig = {
    styleOverrides: {
        root: ({ ownerState, theme }) => (Object.assign({ '&.MuiOutlinedInput-root': {
                '& fieldset': {
                    border: `1px solid ${theme.customColors.input.border}`,
                },
                '&:hover fieldset': {
                    border: `1px solid ${theme.customColors.input.borderHover}`,
                },
                '&.Mui-focused fieldset': {
                    border: `1px solid ${theme.customColors.input.borderActive}`,
                    boxShadow: `0px 0px 0px 3px ${theme.customColors.surface.shadow}`,
                },
            } }, (ownerState.size === 'small' && {
            height: 40,
        }))),
    },
};
export default MuiSelectConfig;
