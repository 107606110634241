const MuiButtonBaseConfig = {
    defaultProps: {
        disableRipple: true,
    },
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            // NOTE: pagination button overrides
            '&.MuiPaginationItem-root': {
                '&:hover': {
                    backgroundColor: theme.customColors.shades.primaryLight,
                },
                '&:focus': {
                    boxShadow: '0px 0px 0px 3px rgba(62, 99, 221, 0.20)',
                },
                '&:active': {
                    backgroundColor: theme.palette.primary.light,
                    boxShadow: '0px 0px 0px 3px rgba(62, 99, 221, 0.20)',
                },
                '&.MuiSelected': {
                    backgroundColor: theme.customColors.portfolio.hover,
                    // '&:hover,:focus,:active': {
                    //     boxShadow: 'none',
                    //     backgroundColor: 'yellow',
                    //     // backgroundColor: theme.customColors.portfolio.hover,
                    // },
                    '&:hover': {
                        backgroundColor: 'green',
                    },
                },
                '&.MuiSelected:hover': {
                    backgroundColor: 'yellow',
                },
            },
        }),
    },
};
const MuiButtonConfig = {
    styleOverrides: {
        root: ({ ownerState, theme }) => (Object.assign(Object.assign({ textTransform: 'none', minHeight: '32px', borderRadius: '4px', '& .MuiButton-startIcon': {
                marginLeft: 0,
                marginRight: 6,
            } }, (ownerState.size === 'large' && {
            padding: '14px 24px',
        })), (ownerState.size === 'medium' && {
            padding: '6px 10px',
        }))),
    },
    variants: [
        {
            props: { variant: 'contained', color: 'primary' },
            style: ({ theme }) => ({
                '&:active': {
                    boxShadow: `0px 0px 0px 3px ${theme.customColors.button.activeShadowPrimary}`,
                },
                '&.Mui-disabled': {
                    opacity: 0.5,
                    color: '#fff',
                    backgroundColor: theme.palette.primary.main,
                },
            }),
        },
        {
            props: { variant: 'contained', color: 'secondary' },
            style: ({ theme }) => ({
                '&:active': {
                    boxShadow: `0px 0px 0px 3px ${theme.customColors.button.activeShadowSecondary}`,
                },
                '&.Mui-disabled': {
                    opacity: 0.5,
                    color: '#fff',
                    backgroundColor: theme.palette.secondary.main,
                },
            }),
        },
        {
            props: { variant: 'contained', color: 'success' },
            style: ({ theme }) => ({
                '&:active': {
                    boxShadow: `0px 0px 0px 3px ${theme.customColors.button.activeShadowSuccess}`,
                },
                '&.Mui-disabled': {
                    opacity: 0.5,
                    color: '#fff',
                    backgroundColor: theme.palette.success.main,
                },
            }),
        },
        {
            props: { variant: 'disabled-contained', color: 'primary' },
            style: ({ theme }) => ({
                cursor: 'inherit',
                color: `${theme.palette.primary.main}`,
                backgroundColor: `${theme.customColors.badge.profileTabActive.surface}`, // TODO: name this color differently
                '&.Mui-disabled': {
                    color: `${theme.palette.primary.main}`,
                    backgroundColor: `${theme.customColors.badge.profileTabActive.surface}`, // TODO: name this color differently
                },
                '&:hover': {
                    backgroundColor: `${theme.customColors.badge.profileTabActive.surface}`,
                },
            }),
        },
        {
            props: { variant: 'secondary', color: 'secondary' },
            style: ({ theme }) => ({
                color: `${theme.palette.secondary.main}`,
                backgroundColor: `${theme.palette.secondary.light}`,
                '&:hover': {
                    backgroundColor: `${theme.palette.secondary.light}`,
                },
                '&:active': {
                    boxShadow: `0px 0px 0px 3px ${theme.customColors.button.activeShadowSecondary}`,
                },
            }),
        },
        {
            props: { variant: 'secondary', color: 'info' },
            style: ({ theme }) => ({
                color: `${theme.palette.primary.main}`,
                backgroundColor: `${theme.palette.primary.light}`,
                '&:hover': {
                    backgroundColor: `${theme.customColors.shades.primaryMidLight}`,
                },
                '&:active': {
                    boxShadow: `0px 0px 0px 3px ${theme.customColors.button.activeShadowInfo}`,
                },
            }),
        },
        {
            props: { variant: 'secondary', color: 'warning' },
            style: ({ theme }) => ({
                color: theme.palette.warning.main,
                backgroundColor: theme.palette.warning.light,
                '&:hover': {
                    backgroundColor: theme.customColors.button.warning.hover,
                },
                '&:active': {
                    boxShadow: `0px 0px 0px 3px ${theme.customColors.button.activeShadowWarning}`,
                },
            }),
        },
        {
            props: { variant: 'secondary-noshadow', color: 'info' },
            style: ({ theme }) => ({
                color: `${theme.palette.primary.main}`,
                backgroundColor: `${theme.palette.primary.light}`,
                '&:hover': {
                    backgroundColor: `${theme.customColors.shades.primaryMidLight}`,
                },
                '&:active': {
                    boxShadow: 'none',
                },
            }),
        },
        {
            props: { variant: 'text', color: 'primary' },
            style: ({ theme }) => ({
                border: '1px solid transparent',
                '&:hover': {
                    color: theme.palette.primary.main,
                    border: `1px solid ${theme.palette.primary.main}`,
                },
                '&:active': {
                    color: theme.palette.primary.main,
                    border: `1px solid ${theme.palette.primary.main}`,
                },
            }),
        },
        {
            props: { variant: 'plain', color: 'primary' },
            style: ({ theme }) => ({
                color: theme.palette.primary.main,
                '&:hover': {
                    backgroundColor: theme.palette.primary.light,
                },
                '&:active': {
                    backgroundColor: theme.palette.primary.light,
                    boxShadow: '0px 0px 0px 3px rgba(62, 99, 221, 0.20)',
                },
                '&.Mui-disabled': {
                    opacity: 0.5,
                },
            }),
        },
        {
            // TODO: in a theme change secondary color to be a gray color?
            // or make another color to represent the grey variation
            props: { variant: 'plain', color: 'secondary' },
            style: ({ theme }) => ({
                color: theme.palette.text.secondary,
                '&:hover': {
                    backgroundColor: theme.customColors.menu.border,
                },
                '&:active': {
                    backgroundColor: theme.customColors.menu.border,
                    boxShadow: '0px 0px 0px 3px rgba(136, 144, 150, 0.10)',
                },
                // '&:focus': {
                //     backgroundColor: theme.customColors.surface.surface,
                //     boxShadow: '0px 0px 0px 3px rgba(136, 144, 150, 0.10)',
                // },
                '&.Mui-disabled': {
                    opacity: 0.5,
                },
            }),
        },
        {
            props: { variant: 'pill' },
            style: ({ theme }) => ({
                minHeight: 'auto',
                minWidth: 'unset',
                height: `${theme.spacing_sizes.xs * 5}px`,
            }),
        },
    ],
};
export { MuiButtonBaseConfig, MuiButtonConfig, };
