const MuiPopoverConfig = {
    styleOverrides: {
        root: () => ({
            '&.MuiMenu-root': {
                border: 'none',
            },
        }),
    },
};
export default MuiPopoverConfig;
